<script>
    import {createEventDispatcher} from "svelte";
    import {generateUuid} from "../common/utils.js";

    export let enabled = false;

    const dispatch = createEventDispatcher();
    const uuid = generateUuid();

    let wrapper;

    const handler = (e) => {
        if (isOutside(e.target)) {
            dispatch('outsideClicked');
        }
    }

    $:{
        if (enabled) {
            globalThis.addEventListener('pointerdown', handler);
        } else {
            globalThis.removeEventListener('pointerdown', handler);
        }
    }

    function isOutside(target) {
        return !wrapper.contains(target);
    }
</script>

<svelte:element
    this='div'
    {uuid}
    bind:this={wrapper}
    style="display: contents"
    {...$$restProps}
>
    <slot />
</svelte:element>