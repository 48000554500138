<script>
  import { onMount } from "svelte";
  import dialogPolyfill from "dialog-polyfill";

  export let dialog;
  export let classNames = '';

  onMount(() => dialogPolyfill.registerDialog(dialog));
</script>

<dialog bind:this={dialog} on:close class={classNames}>
  <slot />
</dialog>
